<template>
    <div id="manufacturer">
      <v-container class="secondary mt-10">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="filteredApplications"
              :search="search"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="primary--text font-weight-bold"
                    >Приложения </v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-select
                    v-model="selectedType"
                    :items="applicationTypes"
                    label="Выберите тип приложения"
                    item-text="label"
                    item-value="label"
                    single-line
                    hide-details
                    dense 
                    ></v-select>
                  <v-spacer></v-spacer>
                  <v-text-field
                    class="mr-10"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Поиск"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <v-btn outlined icon @click="createItem">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
  
                  <v-dialog v-model="dialogCreate" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">
                        Добавить приложение
                        </v-card-title>
                        <v-card-text>
                        <v-text-field v-model="newItem.name" label="Название приложения(package.name)" />
                        <v-select v-model="newItem.applicationType" :items="applicationTypes" label="Выберите тип приложения" item-text="label" item-value="value"/>
                        <v-text-field v-model="newItem.description" label="Описание" />
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="createItemConfirm">Добавить</v-btn>
                        <v-btn color="blue darken-1" text @click="closeCreate">Отменить</v-btn>
                        <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5"
                        >Вы точно хотите удалить данное
                        приложение?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete"
                          >Отменить</v-btn
                        >
                        <v-btn
                          color="red darken-1"
                          text
                          @click="deleteItemConfirm"
                          >Удалить</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td @click="editItem(item)">{{ item.name }}</td>
                  <td @click="editItem(item)">{{ item.applicationType }}</td>
                  <td @click="editItem(item)">{{ item.description }}</td>
                  <td style="text-align: center">
                    <v-icon small @click="deleteItem(item)">mdi-dots-vertical</v-icon>
                  </td>
                </tr>
              </template>
              <template v-slot:no-data>
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  <style>
  /* Применяем стили для выпадающего списка */
  .custom-select .v-select__slot {
    padding: 8px 12px; /* Уменьшаем вертикальный отступ */
    min-height: 30px; /* Минимальная высота */
  }
  
  /* Применяем стили для элементов списка */
  .custom-select .v-list-item {
    height: 24px; /* Высота элемента */
    min-height: 24px; /* Минимальная высота элемента */
    line-height: 24px; /* Высота строки */
  }
  
  /* Применяем стили для текста элемента списка */
  .custom-select .v-list-item__title {
    font-size: 12px; /* Размер шрифта */
  }
  
  /* Применяем стили для выпадающего списка при наведении */
  .custom-select .v-list-item:hover {
    background-color: #f0f0f0; /* Цвет фона при наведении */
  }
  </style>
  <script>
  export default {
    name: "ApplicationList",
  
    data: () => ({
      dialogDelete: false,
      dialogCreate: false,
      search: "",
      newItem: {
        name: "",
        applicationType: null,
        description: "",
      },
      selectedType: null,
      applicationTypes: [
        { label: 'CustomerApp', value: 1 },
        { label: 'RestaurantApp', value: 2 },
        { label: 'WebApp', value: 3 }
      ],
      headers: [
        {
          text: "PackageName",
          align: "start",
          value: "name",
        },
        { text: "Тип", value: "applicationType" },
        { text: "Описание", value: "description" },
        { text: "Действия", value: "actions", sortable: false },
      ],
      applications: [],
      profiles: [],
      error: null,
    }),
  
    created() {
      this.initialize();
      // this.profileInfo();
    },
    computed: {
        filteredApplications() {
        // Фильтруем данные внутри таблицы в зависимости от выбранного типа
        if (this.selectedType) {
            console.log(this.selectedType);
            return this.applications.items.filter(
            item => item.applicationType === this.selectedType
            );
        } else {
            return this.applications.items;
        }
        }
    },
    methods: {
      initialize() {
        this.$http
          .put("/api/Application/PagedList", {
            headers: {
                      'Content-Type': 'application/json',
                    },
          })
          .then((response) => {
            this.applications = response.data;
          })
          .catch((error) => (this.error = error));
      },
    //   profileInfo() {
    //     this.$http
    //       .get("/api/Contact/GetMyProfile")
    //       .then((response) => {
    //         this.profiles = response.data;
    //       })
    //       .catch((error) => (this.error = error));
    //   },
  
  
      deleteItem(item) {
        this.editedIndex = this.applications.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      },
  
      deleteItemConfirm() {
        this.$http
          .delete(`/api/Application/${this.editedItem.id}`)
          .then(() => {
            this.applications.items.splice(this.editedIndex, 1);
            this.closeDelete();
          })
          .catch((error) => {
            alert(error);
          });
      },
  
      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },
  
      createItem() {
        this.dialogCreate = true;
      },
  
      closeCreate() {
        this.dialogCreate = false;
      },
  
      createItemConfirm() {
        this.$http
          .post("/api/Application", this.newItem)
          .then(() => {
            this.initialize();
            this.dialogCreate = false;
            this.newItem = {
              name: "",
              applicationType: "",
              description: "",
            };
          })
          .catch((error) => {
            this.error = error;
          });
      },
  
      editItem(item) {
        this.$router.push({ path: `/admin/application/${item.id}` });
      },
    },
  };
  </script>
  